export const NAME_KEY = "LoggedUserName";
export const TOKEN_KEY = "LoggedInToken";
export const USER_ID_KEY = "user_id";
export const USER_TIER = "userTier";
export const REFRESH_TOKEN_KEY = "refresh-token";
export const NAME = "username";
export const ROLES = {
  ACADEMIC: {
    TUTOR: "TUTOR",
    STUDENT: "STUDENT",
    ADMIN: "ADMIN",
  },
};
export const SUBROLE = "sub_role";
export const USER_ROLE = "user_role";

export const UserRoles = [
  {
    type: "Individual",
    label: "Individual",
  },
  {
    type: "Academic",
    label: "Academic",
  },
  {
    type: "Company",
    label: "Company",
  },
];

export const queryKeys = {
  USER_DETAILS: "userdetails",
  ASSESSMENT_DETAILS: "assessmentdetails"
};