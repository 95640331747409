import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const industryTypes = [
  {
    type: "Individual",
    label: "Individual",
  },
  {
    type: "Academic",
    label: "Academic",
  },
  {
    type: "Company",
    label: "Company",
  },
];

export default function SignUpProfilePage() {
  const [industryType, setIndustryType] = useState("Academic");
  const navigate = useNavigate();

  return (
    <div className="flex justify-center flex-col  overflow-clip w-full items-center">
      <div className=" p-8 rounded-md w-full max-w-md  overflow-hidden flex flex-col items-start justify-start ">
        <h1 className="text-4xl mb-4 text-white">Sign up to BakPak</h1>
        <h3 className="text-2xl mb-4 text-white">Continue as,</h3>
        <div className="flex flex-col space-y-8 w-full">
          <div className="flex justify-between w-full">
            x
            {industryTypes.map((item) => (
              <button
                key={item.type}
                onClick={() => setIndustryType(item.type)}
                className={`px-6 py-4  mr-[5px] rounded-xl ${
                  industryType === item.type
                    ? "bg-sky-950 border-2 border-sky-400 text-sky-400"
                    : " text-white px-4 py-2 rounded-md flex-1 bg-slate-700"
                }`}
              >
                {item.label}
              </button>
            ))}
          </div>

          <div className="w-full">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white w-full py-2 rounded-md"
              onClick={() =>
                navigate("/auth/signup", {
                  state: { industryType: industryType },
                })
              }
            >
              Continue Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
