import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AssessmentContext = createContext();

export const AssessmentProvider = ({ children }) => {
  const navigate = useNavigate();
  const [assessment, setAssessment] = useState(null);

  useEffect(() => {
    if (!assessment && window.location.pathname.includes("startAssessment")) {
      navigate("/assessment");
    }
  }, [assessment, navigate]);

  return (
    <AssessmentContext.Provider value={{ assessment, setAssessment }}>
      {children}
    </AssessmentContext.Provider>
  );
};

export const useAssessment = () => {
  return useContext(AssessmentContext);
};
