import { useState } from "react";
import { NAME, ROLES, SUBROLE, USER_ROLE } from "../constants/key.const";
import Cookies from "js-cookie";
import Dropzone from "react-dropzone";
import Modal from "../components/common/Modal";
import { useUserDetails } from "../hooks/auth/useUserDetails";
import { useUpdateUser } from "../hooks/auth/useUpdateUser";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../src/constants/key.const';

export default function ProfileSettings() {
  const userId = Cookies.get("user_id");
  const {data:userdetails} = useUserDetails(userId);
  const { mutate: updateUser, isLoading } = useUpdateUser();
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    grade: "",
    school: "",
    profile_image:"",
  });
  const [initialFormData] = useState({
    first_name: "",
    last_name: "",
    grade: "",
    school: "",
    profile_image: "",
  });

  const [selectedImage, setSelectedImage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  console.log(Cookies);
  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const imageUrl = acceptedFiles[0];
      setSelectedImage(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        profile_image: imageUrl,
      }));

      setIsModalOpen(false);
    }
  };

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const changedFields = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialFormData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changedFields).length > 0) {
      updateUser(
        { userId, payload: formData },
        {
          onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [queryKeys.USER_DETAILS] });
            console.log("User details updated successfully!");
            toast.success("User details updated successfully!");
          },
          onError: (error) => {
            console.error("Error updating user details:", error);
            toast.error("Error updating details, please try again.");
          },
        }
      );
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      {/* Profile Header */}
      <div className="bg-[#F5F8FF] rounded-xl p-4 mb-8 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 rounded-full overflow-hidden">
            <img
              src={userdetails?.data?.profile_image}
              alt="Profile"
              width={48}
              height={48}
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h2 className="text-lg font-semibold">
              {userdetails?.data?.first_name} {userdetails?.data?.last_name}
            </h2>
            <button className="text-sm text-gray-500 hover:text-gray-700">
              View Profile
            </button>
          </div>
        </div>
        <Modal>
          <Modal.Open opens="upload">
            <button
              className="bg-blue-500 text-white px-6 py-2 rounded-md text-sm hover:bg-blue-600 transition-colors"
              onClick={() => setIsModalOpen(true)}
            >
              Upload
            </button>
          </Modal.Open>
          {isModalOpen && (
            <Modal.Window name="upload">
              <div className="space-y-6 fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-50">
                <Dropzone
                  onDrop={handleDrop}
                  accept={{
                    "image/jpeg": [".jpg", ".jpeg"],
                    "image/png": [".png"],
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="text-xl ">
                          Drop an image here or{" "}
                          <span className="text-blue-500 underline decoration-dotted">
                            click
                          </span>{" "}
                          to select image
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </Modal.Window>
          )}
        </Modal>
      </div>

      {/* Contact Information Form */}
      <div>
        <h3 className="text-xl font-semibold mb-6">Contact information</h3>
        <form className="grid grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="first_name"
              placeholder={userdetails?.data?.first_name}
              value={formData.first_name}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-md placeholder-gray-400"
            />
          </div>

          <div>
            <label
              htmlFor="grade"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Grade
            </label>
            <input
              type="text"
              id="grade"
              name="grade"
              placeholder="Grade"
              value={formData.grade}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-md placeholder-gray-400"
            />
          </div>

          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="last_name"
              placeholder={userdetails?.data?.last_name}
              value={formData.last_name}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-md placeholder-gray-400"
            />
          </div>

          <div>
            <label
              htmlFor="school"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              School
            </label>
            <input
              type="text"
              id="school"
              name="school"
              placeholder="School"
              value={formData.school}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-md placeholder-gray-400"
            />
          </div>

          <div className="col-span-2 flex justify-end mt-4">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-blue-500 text-white px-8 py-2 rounded-md text-sm hover:bg-blue-600 transition-colors"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
