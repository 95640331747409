import React, { createContext, useContext, useState } from "react";
import { SUBROLE, USER_ROLE } from "../constants/key.const";
import Cookies from "js-cookie";

const RoleContext = createContext();
export const useRole = () => {
  return useContext(RoleContext);
};

export const RoleProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(() => Cookies.get(USER_ROLE));
  const [subRole, setSubRole] = useState(() => Cookies.get(SUBROLE));

  return (
    <RoleContext.Provider
      value={{ userRole, subRole, setUserRole, setSubRole }}
    >
      {children}
    </RoleContext.Provider>
  );
};
