
import { useQuery } from "@tanstack/react-query";
import { userDetails } from "../../services/auth.service"; 
import { queryKeys } from "../../constants/key.const";

export const useUserDetails = (userId) => {
  return useQuery({
    queryKey: [queryKeys.USER_DETAILS],
    queryFn: () => userDetails(userId),
    enabled: !!userId, 
  });
};
