import React, { Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import AuthGuard from "./AuthGuard";
import "react-toastify/dist/ReactToastify.css";
import SettingLayout from "./Layout/SettingLayout";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PasswordSettings from "./pages/PasswordSetting";
import ProfileSettings from "./pages/Profile";
import SignUpProfilePage from "./pages/SignUpProfilePage";
import { ROLES, SUBROLE, USER_ROLE } from "./constants/key.const";
import { useRole } from "./contexts/RoleContext";
import { AssessmentProvider } from "./contexts/AssessmentContext";

const ResultNewHome = React.lazy(() => import("./components/ResultNewHome"))
const SlugAssessmentPage = React.lazy(() => import("./pages/SlugAssessmentPage"))
const ResultInfoNew = React.lazy(() => import("./components/ResultInfoNew"))
const StudentPage = React.lazy(() => import("./pages/ClassroomStudent"));
const OtpForForgotPassword = React.lazy(() =>
  import("./pages/OtpForForgotPassword")
);
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const StudentListPage = React.lazy(() =>
  import("./pages/ClassroomStudentsList")
);
const SideBar = React.lazy(() => import("./components/SideBar"));
const AssessmentHome = React.lazy(() => import("./components/AssessmentHome"));
const DashboardPage = React.lazy(() => import("./components/DashboardPage"));
const GenerateQuestions = React.lazy(() =>
  import("./components/GenerateQuestions")
);

const UserAssessments = React.lazy(() => import("./pages/UserAssessments"));
const ReviewPage = React.lazy(() => import("./pages/ReviewPage"));
const FinishSignUp = React.lazy(() => import("./pages/Academics/FinishSignUp"));
const ForgotPasswordPage = React.lazy(() =>
  import("./pages/Academics/ForgotPasswordPage")
);
const AssessmentInfo = React.lazy(() => import("./components/AssessmentInfo"));
const SubAssessment = React.lazy(() => import("./components/SubAssessment"));
const AssessmentData = React.lazy(() => import("./components/AssessmentData"));
// const ResultHome = React.lazy(() => import("./components/ResultHome"));
// const ResultInfo = React.lazy(() => import("./components/ResultInfo"));
const AssessmentDetails = React.lazy(() =>
  import("./components/AssessmentDetails")
);
const LoginPage = React.lazy(() => import("./components/LoginPage"));
const LoginLayout = React.lazy(() => import("./Layout/LoginLayout"));
const SignUpPage = React.lazy(() => import("./components/SignUpPage"));
const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const PricingPage = React.lazy(() => import("./pages/PricingPage"));
const PaymentSuccess = React.lazy(() => import("./pages/PaymentSuccess"));
const NotificationPage = React.lazy(() => import("./pages/Notifications"));
const StartAssessment = React.lazy(() => import("./pages/StartAssessment"));
const AdminDashboard = React.lazy(() => import("./pages/AdminDashboard"));
const ClassroomPage = React.lazy(() => import("./pages/ClassroomPage"));
const AssessmentStudentHome = React.lazy(() =>
  import("./pages/AssessmentStudentHome")
);
const AssessmentDetailsID = React.lazy(() =>
  import("./pages/AssessmentDetailsID")
);
const OtpPage = React.lazy(() => import("./pages/OtpPage"));

const App = () => {
  const { subRole } = useRole();
  return (
    <div>
      <Router>
        <AssessmentProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* <Route path="/auth/finishsignup" element={<FinishSignUp />} /> */}
              <Route
                path="payment-success"
                element={
                  <AuthGuard>
                    <PaymentSuccess />
                  </AuthGuard>
                }
              />
              <Route path="auth" element={<LoginLayout />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="signupoptions" element={<SignUpProfilePage />} />
                <Route path="signup" element={<SignUpPage />} />
                <Route path="verify-otp" element={<OtpPage />} />
                <Route path="otp" element={<OtpForForgotPassword />} />
                <Route path="forgotpassword" element={<ForgotPasswordPage />} />
                <Route path="resetpassword" element={<ResetPassword />} />
              </Route>
              <Route
                path="/home"
                element={
                  <AuthGuard>
                    <SideBar />
                  </AuthGuard>
                }
              >
                <Route
                  path="notifications"
                  element={
                    <AuthGuard>
                      <NotificationPage />
                    </AuthGuard>
                  }
                />
                <Route path="settings" element={<SettingLayout />}>
                  <Route path="terms" element={<TermsAndConditions />} />
                  <Route path="privacy_policy" element={<PrivacyPolicy />} />
                  <Route
                    index
                    path="password_setting"
                    element={<PasswordSettings />}
                  />
                  <Route index path="profile" element={<ProfileSettings />} />
                </Route>
                {subRole !== ROLES.ACADEMIC.STUDENT && (
                  <>
                    <Route
                      path="assessment"
                      element={
                        <AuthGuard>
                          <AssessmentHome />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path="assessment/sub/:grade"
                      element={
                        <AuthGuard>
                          <SubAssessment />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path="assessment/sub/:grade/:sub"
                      element={
                        <AuthGuard>
                          <AssessmentData />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path="assessment/add"
                      element={
                        <AuthGuard>
                          <GenerateQuestions />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path="assessment/info"
                      element={
                        <AuthGuard>
                          <AssessmentInfo />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path="assessment/details"
                      element={
                        <AuthGuard>
                          <AssessmentDetails />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path="assessment/details/:asmt_id"
                      element={
                        <AuthGuard>
                          <AssessmentDetailsID />
                        </AuthGuard>
                      }
                    />
                  
                    <Route
                      path="assessment/review/:id"
                      element={
                        <AuthGuard>
                          <ReviewPage />
                        </AuthGuard>
                      }
                    />
                  </>
                )}
                {subRole === ROLES.ACADEMIC.STUDENT && (
                  <Route path="assessment">
                    <Route index element={<AssessmentStudentHome />} />
                    <Route
                      path="start-assessment"
                      element={<StartAssessment />}
                    />
                    <Route
                      path="start-assignment/:slug"
                      element={<SlugAssessmentPage />}
                    />
                    <Route path="review">
                      <Route path=":id" element={<ReviewPage />} />
                    </Route>          
                  </Route>
                )}
                <Route
                  path="dashboard"
                  element={
                    <AuthGuard>
                      <DashboardPage />
                    </AuthGuard>
                  }
                />
                <Route
                  path="result"
                  element={
                    <AuthGuard>
                      <ResultNewHome />
                    </AuthGuard>
                  }
                />

                <Route
                  path="result/info"
                  element={
                    <AuthGuard>
                      <ResultInfoNew />
                    </AuthGuard>
                  }
                />
                {subRole === ROLES.ACADEMIC.ADMIN && (
                  <Route path="admin" element={<AdminDashboard />} />
                )}
                {subRole !== ROLES.ACADEMIC.STUDENT && (
                  <Route path="classroom">
                    <Route index element={<ClassroomPage />} />
                    <Route path=":classroomId" element={<StudentListPage />} />
                    <Route
                      path=":classroomId/:studentId"
                      element={<StudentPage />}
                    />
                  </Route>
                )}

                <Route
                  path="classroom/:classroomId/assessments"
                  element={<UserAssessments />}
                />
                <Route path="" element={<Navigate to="assessment" />} />
              </Route>
              <Route path="/" element={<LandingPage />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="" element={<Navigate to="auth/login" />} />a
              <Route path="pricing" element={<PricingPage />} />
            </Routes>
          </Suspense>
        </AssessmentProvider>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
