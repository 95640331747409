import { useMutation } from "@tanstack/react-query";
import {toast} from 'react-toastify'
import { changePassword } from "../../services/auth.service";

export const useChangePassword = () => {
  return useMutation({
    mutationFn: (old_password,Password,confirm_password) =>
        changePassword(old_password,Password,confirm_password),  
    onError: (error) => {
        toast.error("Error changing password");
      console.error("Error changing password", error);
    },
  });
};