import { useMutation } from "@tanstack/react-query";
import {toast} from 'react-toastify'
import { updateUser } from "../../services/auth.service";

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: ({userId,payload}) => updateUser(userId,payload),
    onSuccess:() => toast.success('User details updated !!'),
    onError: (error) => {
      toast.error("Error updating details");
      console.error("Error updating details", error);
    },
  });
};