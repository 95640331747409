import { useMutation } from "@tanstack/react-query";
import {toast} from 'react-toastify'
import { deleteUser } from "../../services/auth.service";

export const useDeleteUser = () => {
  return useMutation({
    mutationFn: (userID) =>
        deleteUser(userID),  
    onSuccess: () => {
        toast.success("User Account deleted!");
      },
    onError: (error) => {
        toast.error("Error deleting account");
      console.error("Error deleting account", error);
    },
  });
};