import axios from "axios";
import { TOKEN_KEY } from "../constants/key.const";
import Cookies from "js-cookie";

const axiosInstance = axios.create({
  baseURL: "https://bakpak.ai/api/v1/",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get(TOKEN_KEY);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized, please log in again.");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
