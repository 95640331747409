import { useState } from "react";
import Cookies from 'js-cookie'
import { useChangePassword } from "../hooks/auth/useChangePassword";
import { toast } from "react-toastify";
import Modal from "../components/common/Modal";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {useDeleteUser} from "../hooks/auth/useDeleteUser"
import { USER_ID_KEY } from "../constants/key.const";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function PasswordSettings() {
  const user_id = Cookies.get(USER_ID_KEY)
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {mutate:deactivateUser} = useDeleteUser();
  const { mutate: changePassword } = useChangePassword();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (
      !formData.currentPassword ||
      !formData.newPassword ||
      !formData.confirmPassword
    ) {
      toast.error("Please fill all the fields", { autoClose: 2000 });
      return; 
    }
  
    if (formData.newPassword !== formData.confirmPassword) {
      toast.error("Confirm password should match new password", {
        autoClose: 2000,
      });
      return;
    }
    const payload = {
      old_password: formData?.currentPassword,
        password: formData?.newPassword,
        confirm_password: formData?.confirmPassword,

    }
  
    changePassword(payload,
      {
        onSuccess: () => {
          toast.success("Password Updated", { autoClose: 2000 });
        },
        onError: () => {
          
          toast.error("Error updating password", { autoClose: 2000 });
        },
      }, 
    );
  };
  
  const handleDeactivate = () => {
    deactivateUser(user_id      
      ,{
      onSuccess: () => {
        navigate("/login");
      },
      onError: ()=>{
        toast.error("Account deletion failed",{ autoClose: 2000 })
      }   
    })
  };

  return (
    <div className="w-full p-6">
      <h2 className="text-2xl font-semibold mb-6">Change password</h2>
      <form  className="w-[50%] space-y-6">
        <div className="flex items-center justify-between space-x-4 w-full">
          <label
            htmlFor="currentPassword"
            className="block text-sm font-medium text-gray-700 mb-1 text-nowrap w-40"
          >
            Current password
          </label>
          <div className="relative">
          <input
            type={showCurrentPassword ? "text" : "password"}
            id="currentPassword"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            className="px-3 py-2 border border-gray-300 rounded-md pr-10"
          />
          <span
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-400"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              >
                {showCurrentPassword ? <FiEyeOff /> : <FiEye />}
              </span></div>
        </div>

        <div className="flex items-center justify-between space-x-4">
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium text-gray-700 mb-1 text-nowrap w-40"
          >
            New password
          </label>
          <div className="relative">
          <input
            type={showNewPassword ? "text" : "password"}
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            className="px-3 py-2 border border-gray-300 rounded-md pr-10"
          />
          <span
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-400"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <FiEyeOff /> : <FiEye />}
              </span></div>
        </div>

        <div className="flex items-center justify-between space-x-4">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-gray-700 mb-1 text-nowrap w-40"
          >
            Confirm new password
          </label>
          <div className="relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className="px-3 py-2 border border-gray-300 rounded-md pr-10"
          />
          <span
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-400"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
              </span></div>
        </div>

        <button
          
          onClick={handleUpdate}
          className="w-48 bg-blue-500 text-white py-2 px-4 rounded-md"
        >
          Update Password
        </button>
      </form>

      <div className="mt-12 bg-gray-200 rounded p-6">
        <h3 className="text-lg font-medium text-custom-pink-1 mb-2">
          Deactivate account
        </h3>
        <div className="flex spacex-x-6 items-start justify-between">
          <p className="text-sm text-gray-500 mb-4">
            Once you delete your account, there is no going back. Please be
            certain.
          </p>
          <div className="flex justify-between items-center">
            <Modal>
              <Modal.Open opens="deactivate">
                <button className="bg-custom-pink-1 text-white py-1 px-4 rounded-md text-base font-medium h-12">
                  Deactivate
                </button>
              </Modal.Open>
              <Modal.Window name="deactivate">
                <div className="space-y-6 fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-50">
                  <div className="flex flex-col gap-2 p-4  items-center justify-center">
                    <h1 className="text-xl font-semibold">
                      Do you wish to permanently delete your account?
                    </h1>
                    <h2>Once deleted there is no going back</h2>
                    <button
                      className="px-4 py-2 bg-custom-pink-1 text-white rounded-lg"
                      onClick={handleDeactivate}
                    >
                      Deactivate
                    </button>
                  </div>
                </div>
              </Modal.Window>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
