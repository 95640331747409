import React from 'react';
import { Navigate } from 'react-router-dom';
import { TOKEN_KEY } from './constants/key.const';
import Cookies from "js-cookie";

const AuthGuard = ({ children }) => {
  const isAuthenticated = !!Cookies.get(TOKEN_KEY); // Replace TOKEN_KEY with your actual key
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default AuthGuard;
