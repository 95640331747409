import axiosInstance from "./api";

export const login = async (payload) => {
  const res = await axiosInstance.post("/auth/login/", { ...payload });
  return res.data;
};

export const registration = async (payload) => {
  const res = await axiosInstance.post("/auth/signup/", {
    ...payload,
  });
  return res.data;
};

export const emailVerify = async (payload) => {
  const res = await axiosInstance.post("/auth/email-verify/", {
    ...payload,
  });
  return res.data;
};

export const logout = async (refresh) => {
  const res = await axiosInstance.post("/auth/logout/", { refresh: refresh });
  return res.data;
};

export const changePassword = async (
  payload
) => {
  const res = await axiosInstance.post("/auth/change-password/", {
    ...payload
  });
  return res.data;
};

export const refreshToken = async (refresh) => {
  const res = await axiosInstance.post("/auth/refresh-token/", {
    refresh: refresh,
  });
  return res.data;
};

//forgot password

export const checkEmail = async (email) => {
  const res = await axiosInstance.post("/auth/check-email/", { email: email });
  return res.data;
};

export const verifyOtp = async (payload) => {
  const res = await axiosInstance.post("/auth/verify-opt/", {
    ...payload,
  });
  return res.data;
};

export const resetPassword = async (payload) => {
  const res = await axiosInstance.post("/auth/reset-password/", {
    ...payload
  });
  return res.data;
};
export const resendOtp = async (email) => {
  const res = await axiosInstance.post("/auth/resend-otp/", { email: email });
  return res.data;
};

//deleteUser
export const deleteUser = async (userId) => {
  const res = await axiosInstance.delete(`/auth/user/${userId}/`);
  return res.data;
};


//update userdetails
export const updateUser = async (userId,payload) => {
  const res = await axiosInstance.patch(`/auth/user/${userId}/`,payload);
  return res.data;
};

export const userDetails = async (userId) => {
  const res = await axiosInstance.get(`/auth/user/${userId}/`);
  return res.data;
};